$font-weight-lighter:         200;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-medium:          500;
$font-weight-semibold:        500;
$font-weight-bold:            600;
$font-weight-bolder:          700;

$border-width: 0.075rem;

$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "3x2": calc(2 / 3 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
);

$blue:      #0071e3;

$border-one-secondary:		#D8D9E0;
$border-two-secondary:		#CDCED7;
$border-three-secondary:	#B9BBC6;

$font-size-lg:								1rem;

$card-cap-bg: white;
$card-border-width: 0;
$card-cap-padding-y: 1rem;
$card-cap-padding-x: 1rem;

$input-btn-padding-y-sm:      .15rem;
