$modal-backdrop-bg: rgba(10, 18, 71, 0.75);
$modal-backdrop-opacity: .75;
$nav-pills-border-radius: 50rem;

@import 'bootstrap';
@import 'glightbox/dist/css/glightbox.css';

// @import 'components/offcanvas/offcanvas';
// @import '../components/offcanvas/offcanvas';
@import '~mmenu-js/dist/mmenu.css';
@import 'custom/mmenujs';

@import 'custom/custom-overwrites';
@import 'custom/horizontal-scroll';
