#mmenu:not(.mm-menu) {
  display: none !important;
}

.mm-menu {
  z-index: 502;
}

.mm-menu--theme-dark {
  --mm-color-background: var(--bs-dark);
}

.mm-menu--position-left,
.mm-menu--position-left-front,
.mm-menu--position-right,
.mm-menu--position-right-front {
  width: 100%;
}

.mm-menu--iconbar-left .mm-iconbar {
  background-color: var(--bs-pax-secondary);
  color: var(--bs-light);
}

.mm-iconbar__bottom {
  padding-bottom: 80px;
}

.mm-menu--iconbar-left .mm-iconbar .mm-iconbar__top::before {
  content: "";
  width: 46px;
  height: 50px;
  display: flex;
  margin: 0 2px;
  background-image: url(../../logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.mm-menu {
  --mm-listitem-size: 56px;
  font-size: 1.1rem;
}

.mm-listitem--selected {
  color: var(--bs-primary);
}

.mm-navbars--top {
  border-bottom: 0;
  .mm-navbar:first-child {
    min-height: 65px;
    background: var(--bs-pax-secondary);
    .mm-menu-logo {
      display: none;
    }
    .mm-btn.mm-hidden {
      + .mm-menu-logo {
        margin-left: 1rem;
        display: flex;
      }
    }
    .mm-btn--prev {
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 65px;
      /* background-color: var(--bs-primary); */
      &::before {
        border: none;
        transform: none;
        bottom: unset;
        top: unset;
        width: initial;
        height: initial;
        position: static;
        content: "\f060";
        font-family: "Font Awesome 6 Free", sans-serif;
        font-weight: bold;
        color: white;
        font-size: 1.4rem;
      }
      &.mm-hidden {
        + .mm-menu-logo {
          margin-left: 1rem;
          display: flex;
        }
      }
    }
    .mm-navbar__breadcrumbs {
      color: white;
      flex: 1 1 100%;
      justify-content: center;
      padding: 0 !important;
      margin: 0 1rem 0 1rem;

      &::-webkit-scrollbar {
        height: 0;
        width: 0;
        display: none;
        background: transparent;
        scrollbar-color: transparent transparent;
        -ms-overflow-style: none;
        overflow-y: -moz-hidden-unscrollable;
      }
  
      &::-webkit-scrollbar:horizontal {
        height: 0;
        width: 0;
        display: none;
        background: transparent;
        scrollbar-color: transparent transparent;
        -ms-overflow-style: none;
        overflow-y: -moz-hidden-unscrollable;
      }
  
      &::-webkit-scrollbar-thumb:horizontal {
        height: 0;
        width: 0;
        display: none;
        background: transparent;
        scrollbar-color: transparent transparent;
        -ms-overflow-style: none;
        overflow-y: -moz-hidden-unscrollable;
      }

      span {
        font-size: inherit;
      }

    }
    .mm-btn--close {
      width: 65px;
      /* background-color: var(--bs-pax-primary); */
      &::before {
        content: "\f00d";
        font-family: "Font Awesome 6 Free", sans-serif;
        font-weight: bold;
        color: white;
        font-size: 1.4rem !important;
      }
    }
  }
}

@-moz-document url-prefix() {
	.navbar__breadcrumbs {
		scrollbar-width: none;
	}
}

.mm-iconbar__top a:active,
.mm-iconbar__top a:hover,
.mm-iconbar__top a:visited,
.mm-iconbar__top a:focus,
.mm-iconbar__bottom a:active,
.mm-iconbar__bottom a:hover,
.mm-iconbar__bottom a:visited,
.mm-iconbar__bottom a:focus {
  background-color: var(--bs-primary-border-subtle);
}

.mm-menu--theme-dark {
  --mm-color-background-emphasis: rgb(0, 0, 0, 0.5);
}

.mm-listitem__text {
  display: flex !important;
  align-items: center;
  justify-content: start;
}

.mm-menu--selected-parent .mm-listitem__btn,
.mm-menu--selected-parent .mm-listitem__text {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.mm-listitem .mm-listitem__btn:hover,
.mm-listitem .mm-listitem__text:hover,
.mm-listitem .mm-listitem__btn:focus,
.mm-listitem .mm-listitem__text:focus {
  color: var(--bs-primary) !important;
  background: var(--mm-color-background-emphasis) !important;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.mm-menu--selected-parent .mm-listitem__btn:hover,
.mm-menu--selected-parent .mm-listitem__text:hover,
.mm-menu--selected-parent .mm-listitem__btn:focus,
.mm-menu--selected-parent .mm-listitem__text:focus {
  color: var(--bs-primary) !important;
  background: var(--mm-color-background-emphasis) !important;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
