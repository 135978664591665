nav.scrolling-wrapper {
	position: relative;
	z-index: 2;
	overflow-y: hidden;
	scroll-behavior: smooth;
	display: flex;

	ul, ol {
		display: inline-flex;
		flex-wrap: nowrap;
		padding: 0;
		padding-left: 0;
		margin-block: 0;
		overflow-x: auto;
		list-style: none;
		text-align: center;
		white-space: nowrap;

		&::-webkit-scrollbar {
			height: 0;
			width: 0;
			display: none;
			background: transparent;
			scrollbar-color: transparent transparent;
			-ms-overflow-style: none;
			overflow-y: -moz-hidden-unscrollable;
		}

		&::-webkit-scrollbar:horizontal {
			height: 0;
			width: 0;
			display: none;
			background: transparent;
			scrollbar-color: transparent transparent;
			-ms-overflow-style: none;
			overflow-y: -moz-hidden-unscrollable;
		}

		&::-webkit-scrollbar-thumb:horizontal {
			height: 0;
			width: 0;
			display: none;
			background: transparent;
			scrollbar-color: transparent transparent;
			-ms-overflow-style: none;
			overflow-y: -moz-hidden-unscrollable;
		}
	}

	a {
		display: block;
	}
}

@-moz-document url-prefix() {
	.scrolling-wrapper ul,
	.scrolling-wrapper ol {
		scrollbar-width: none;
	}
}
